@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,400;1,300&family=Gelasio:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display:ital,wght@0,400;0,700;0,900;1,900&family=Frank+Ruhl+Libre:wght@400;500;700;900');


/* 
font-family: 'Cormorant Garamond', serif;         too light for nav
font-family: 'Gelasio', serif;                    no for nav, yes for p -better at defining space between links
font-family: 'Playfair Display', serif;           ok for headings
font-family: 'Frank Ruhl Libre', serif;           
*/


.App {
  text-align: center;
  background-color:  rgb(255, 242, 221);
}


/* ******************** START OF NAV  *************************** */
nav{
  min-height: 50px;
  padding: 10px;
  justify-content: center;
  font-size: 20px ;
  font-family: 'Gelasio', serif;
  /* font-weight: bold; */
}
#navlinks .active{
  color: red
}

#logo {
  position: absolute;
  left: 25px;
  max-height: 95px;
  max-width: 95px;
}

#link1, #link2, #link3{
  position: absolute;
  right: 10px;
  color: #000000;
  
  text-align: right;
  text-decoration: none ;
}

#link1{margin-right: 200px;}/*About */
#link2{margin-right: 110px;}/*Demos */
#link3{margin-right: 10px;}/*Blog */
/* #link4{margin-right: .51rem}Contact */

/* ****************************** END OF NAV  *************************** */
/* --------------------------   HEADER   ------------------------------------ */
#header{
  background-color: #000000;
 }

#header-image{
  max-height: 500px;
  width: 100%;
}

#header-text{
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100px;
  left: 100px;;
  text-align: left;
  color: white;
  
}

#header-text h1{font-size: 60px; 
  font-family: 'Playfair Display', serif; 
  font-weight: 700;
  text-shadow: rgb(182, 182, 181) 1px 1px 3px;
}

#header-text h2{font-size: 50px; 
  color:rgb(246, 70, 70);
  font-family:'Playfair Display', serif;
  font-weight: 700;         
  letter-spacing: .3rem;
  text-shadow: rgb(0, 0, 0) 3px 2px 2px;
  text-align: start;
}

#header-text h4{
  font-size: 32px; 
  font-family: 'Gelasio', serif;
  text-shadow: rgb(182, 182, 181) 1px 1px 3px;
 }

#profile-pic{
  position: absolute;
  top: 100px;
  left: 60%;
  max-width: 350px;
  max-height: 400px;
}

@media screen and (max-width: 1200px) {
  nav{
    min-height: 50px;
    padding: 10px;
    font-size: 15px ;
    font-family: 'Gelasio', serif;
  }
  #link1{margin-right: 130px;}/*About */
  #link2{margin-right: 70px;}/*Demos */
  #link3{margin-right: 0px;}/*Blog */
  
  #header-image{
   min-height: 600px;
    width: 100%;
  }
  #header{
    display: flex;
    flex-direction: column;
  }
  
  #header-text{
    top: 100px;
    left: 40px;
    /* flex-wrap: wrap; */
    width: auto;
    color: white;
  }
  #header-text h1{
    font-size: 40px; 
    font-family: 'Playfair Display', serif; 
    font-weight: 700;
    text-shadow: rgb(182, 182, 181) 1px 1px 3px;
  }
  
  #header-text h2{
    font-size: 30px; 
    color:rgb(246, 70, 70);
    font-family:'Playfair Display', serif;
    font-weight: 700;         
    letter-spacing: .3rem;
    text-shadow: rgb(0, 0, 0) 3px 2px 2px;
    text-align: start;
  }
  
  #header-text h4{
    font-size: 24px; 
    font-family: 'Gelasio', serif;
    text-shadow: rgb(182, 182, 181) 1px 1px 3px;
   }
  
  #profile-pic{
    top: 350px;
    left: 20%;
    width: 150px;
    height: 300px;
  }
  
  #blogs {
    flex-direction: column;
  }
  #blog1, #blog2, #blog3 {width: 80%;}
}

/* ******************************* END OF HEADER ******************************* */
/* --------------------------   ABOUT SECTION   ------------------------------------ */
 #about{
  margin-top: 40px;
  border-top: #ff0000 1px solid;
  border-right: #ff0000 3px solid;
  border-radius: 0 35px ;
 }

 #about h2, #projects h2 , #blogs h2{
  font-size: 40px;
  text-align: center;
  padding: 2rem;
  font-family:'Playfair Display', serif;
}

#bitAbout{
display: flex;
flex-direction: column;
flex-wrap: wrap;
font-size: 22px;
font-family: 'Frank Ruhl Libre', serif;
line-height: 2rem;
}

#meCollage{
  width: 90%;
  height: 400px;
  margin-bottom: 2rem; 
}

#moreAbout{
  color: rgb(29, 120, 181);  
}

#moreAbout:hover{
    text-decoration: underline;
    color: rgb(25, 91, 136);
  }
/* ******************************* END OF ABOUT ******************************* */
/* --------------------------   PROJECT SECTION  ------------------------------------ */
#projects{
  display: inline-flex;
  /* margin: 3rem; */
  min-height: 80dvh;
  border-top: #ff0000 1px solid;
  border-left: #ff0000 3px solid;
  border-radius:  35px 0;
}

#projects .column{
  display: inline;
  flex-direction: column;
}

#project1 p, #project2 p, #project3 p, #project4 p, #project5 p{
  padding: 1rem;
  font-size: 18px;
  font-family: 'Frank Ruhl Libre', serif;
}

#project1, #project2, #project3,#project4 , #project5 {
  height: 460px;
  margin-top: 3rem;
} 

#project-image1, #project-image2, #project-image3, #project-image4, #project-image5{
  display: inline;
  object-fit: cover;
  width: 90%;
  height: 65%;
  padding-bottom: 1rem;
  border-bottom: #ff0000  1px solid;
}

#demos-link{
color: rgb(29, 120, 181);
text-align: center;
font-size: 22px;
font-family: 'Frank Ruhl Libre', serif;
}
#demos-link:hover{
  text-decoration: underline;
  color: rgb(25, 91, 136);
}

/* ******************************* END OF PROJECTS ******************************* */
/* --------------------------   BLOG SECTION  ------------------------------------ */

#blogs{
  display: flex;
  padding-bottom: 40px; 
  min-height: 40vh;
  border-top: #ff0000 1px solid;
  border-right: #ff0000 3px solid;
  border-radius: 0 35px ;
}


#blog1, #blog2, #blog3 {
  background-color: rgb(247, 64, 64);
  border-radius: 25px;
  margin-left:3rem; 
  padding-top: 1rem;
  margin-bottom: 1rem;
  min-width: 30%;
  max-width: 70%;
  font-family: 'Frank Ruhl Libre', serif;
} 

h3{
  font-size: 28px;
}
#blog1 a, #blog2 a, #blog3 a{
  text-decoration: none;
  color: white;
}

#blog1 a:visited, #blog2 a:visited, #blog3 a:visited{
  color: rgb(60, 31, 31);
}

#blogs-link{
  margin-top: 2rem;
  text-align: center;
  font-size: 22px;
  font-family: 'Frank Ruhl Libre', serif;
  }

/* ******************************* END OF BLOG SECTION  ******************************* */
/* --------------------------   FOOTER  ------------------------------------ */
footer{
  background-color: rgb(26, 26, 26) ;
  height: 50vh;
  color: rgb(252, 248, 242);
}
footer h2{
  padding-top: 5rem;
  font-weight: bold;
}

footer button{
  margin-bottom: 3rem;
  font-size: larger;
  border-radius: 8px;
  padding: .5rem;
  font-family: 'Frank Ruhl Libre', serif;
}
footer i{
  margin-bottom: 5rem;
  font-size: 40px;
}




/* +++++++++++++++++++++++++++++++ ABOUT ME PAGE +++++++++++++++++++++++++++++++ */
#about-page1{
  padding-top: 30px;
  border-top: #ff0000 1px solid;
  border-right: #ff0000 3px solid;
  border-radius: 0 35px ;
}

#about-page2{
  padding-top: 30px;
  border-top: #ff0000 1px solid;
  border-left: #ff0000 3px solid;
  border-radius: 35px 0;
}


#about-page1 h2,#about-page2 h2{
  font-size: 40px;
  text-align: center;
  font-family:'Playfair Display', serif;
}

#bio1, #bio3{
display: flex;
float: left;
text-align: start;
font-size: 22px;
min-height: 50dvh;
width: 80%;
margin-left: 5rem;
margin-top: 4rem;
font-family: 'Frank Ruhl Libre', serif;
}



#bio2{
  display: flex;
  flex-direction: column;
  text-align: start;
  font-size: 22px;
  min-height: 50dvh;
  width: 80%;
  margin-top: 4rem;
  font-family: 'Frank Ruhl Libre', serif;
  }

#about-image1, #about-image3{
  display: flex;
  width: 70%;
  height: 450px;
  float: right;
  margin-right: 5rem;
}

#about-image2{ 
  display: flex;
  width: 60%;
  height: 425px;
  float: left;
  margin-left: 5rem;
}

#values{
text-align: start;
font-size: 20px;
width: 90%;
margin: 5rem;
font-family: 'Frank Ruhl Libre', serif;
}


/* +++++++++++++++++++++++++++++++ CONTACT PAGE +++++++++++++++++++++++++++++++ */
#form-row{
  border-top: #ff0000 1px  solid;
  border-right: #ff0000 3px solid;
  border-bottom: #ff0000 1px  solid;
  border-left: #ff0000 3px solid;
  border-radius: 25px;
  margin: 3rem;
  padding: 1rem;
  width: 100%;
}

form{
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  width: 100%;
  text-align: start ;
  font-weight: bold;
}

p{
  font-size: 25px;
  font-family: 'Frank Ruhl Libre', serif;
}
label{
  margin-top: .5rem;
  font-size: 20px;
  font-family: 'Frank Ruhl Libre', serif;
  letter-spacing: .04rem;
}

input, textarea{
  border-radius: 5px;
  border: #c4b0b0 1px solid;
  
}

input{
  height: 45px;
  padding: 8px;
  font-size: 20px;
  font-family: 'Cormorant Garamond', serif;
}

#form-button1{
  margin: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  border-radius: 10px;
  background-color: rgb(50, 53, 61);
  border: rgb(50, 53, 61) 2px solid; 
  color: white;
  font-family:'Frank Ruhl Libre', serif;
  font-weight: 500;
}

#form-button2{
  margin: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  border-radius: 10px;
  background-color: rgb(249, 250, 250);
  border: rgba(50, 53, 61, 0.681) 2px solid; 
  color: rgb(50, 53, 61);
  font-family:'Frank Ruhl Libre', serif;
  font-weight: 500;
}
#coffee-img{
  margin-top: 6rem;
}
/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
